import { Button, Container, Fab, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { track } from '../utils/analytics';
import CustomCard from '../components/CustomCard/CustomCard';
import CustomCardHeader from '../components/CustomCard/CustomCardHeader';
import HostingTable from '../components/Hosting/HostingTable';
import TrempsTable from '../components/Hosting/TrempsTable';
import { Add } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';
import { HostingContext } from '../contexts/HostingContext';
import { RidesContext } from '../contexts/RidesContext';

const Rides = () => {
  const { isAuthenticated, setOpenLoginDialog } = useContext(AuthContext);
  const { setOpenAddingDialog } = useContext(RidesContext);

  useEffect(() => {}, []);

  const add = () => {
    if (isAuthenticated === true) {
      setOpenAddingDialog(true);
    } else {
      setOpenLoginDialog(true);
    }
  };

  return (
    <Container maxWidth={'md'} disableGutters>
      <Typography variant='h1' sx={{ textAlign: 'start' }}>
        טרמפים דרומה וצפונה
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={12}>
          <CustomCard>
            <CustomCardHeader title='נסיעות מתוכננות' />
            <TrempsTable />
          </CustomCard>
        </Grid>
      </Grid>
      <Fab
        variant='extended'
        size='medium'
        color='primary'
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        onClick={() => add()}
      >
        <Add sx={{ mr: 1 }} />
        הוספת נסיעה
      </Fab>
    </Container>
  );
};

export default Rides;
