import React, { createContext, useEffect } from 'react';
import { usePersistedJsonState } from '../hooks/usePersistedState';
import {
  onRefreshError,
  storeAccessToken,
  storeRefreshToken,
} from '../services/BaseService';
import { auth } from '../Firebase';
import { signOut } from 'firebase/auth';

interface AuthContextProps {
  user?: any;
  isAuthenticated: boolean;
  logout: () => void;
  openLoginDialog: boolean;
  setOpenLoginDialog: (state: boolean) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  logout: () => {},
  setOpenLoginDialog: () => {},
  openLoginDialog: false,
  isAuthenticated: false,
});

function AuthContextProvider(props: any) {
  const [user, setUser] = usePersistedJsonState<any | undefined>('user');
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = usePersistedJsonState<boolean>(
    'isAuthenticated',
    true
  );

  useEffect(() => {
    onRefreshError.cb = () => {
      logout();
    };

    return () => {
      onRefreshError.cb = undefined;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user: any) => {
      setUser(user);
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const logout = () => {
    storeAccessToken(undefined);
    storeRefreshToken(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
    signOut(auth);
    // if (process.env.REACT_APP_NODE_ENV !== 'development') {
    //   mixpanel.reset();
    // }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        logout,
        openLoginDialog,
        setOpenLoginDialog,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
