import { useContext, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { AuthContext } from './contexts/AuthContext';

import Login from './screens/Login';
import Overview from './screens/Overview';

import DashboardLayout from './components/Common/DashboardLayout';

import { Box } from '@mui/material';
import './App.css';
import { track } from './utils/analytics';
import Loader from './components/Common/Loader';
import Hosting from './screens/Hosting';
import AddHostDialog from './components/dialogs/AddHostDialog';
import Rides from './screens/Rides';
import AddRideDialog from './components/dialogs/AddRideDialog';
import MentalHelp from './screens/MentalHelp';
import LoginDialog from './components/dialogs/LoginDialog';

const ProtectedRoute = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean | null;
}) => {
  if (false) {
    const params = ['?goto=', window.location.pathname, window.location.search]
      .filter((a) => !!a)
      .filter((_, __, arr) => arr.length > 1)
      .join('');
    return <Navigate to={'/login' + params} replace />;
  }

  return <Outlet />;
};

function App() {
  const location = useLocation();

  const { user, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // track('startup');
  }, []);

  useEffect(() => {
    // if (hotjar.initialized()) {
    //  hotjar.stateChange(location.pathname);
    // }
    // track('page_view', location.pathname);
  }, [location]);

  return (
    <Box className='App'>
      <AddHostDialog />
      <AddRideDialog />
      <LoginDialog hideButton />
      {/* {user === null ? ( 
        <Loader />
      ) : ( */}
      <Routes>
        {/* <Route path='/login' element={<Login />} />
        <Route path='/auth' element={<Login />} /> */}
        {/* <Route
          path='/'
          element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
        > */}
        <Route element={<DashboardLayout />}>
          <Route path='home' element={<Overview />} />
          <Route path='hosting' element={<Hosting />} />
          <Route path='rides' element={<Rides />} />
          <Route path='mental-support' element={<MentalHelp />} />
          <Route path='*' element={<Navigate to='/home' />} />
        </Route>
        {/* </Route> */}
        <Route path='*' element={<Navigate to='/home' />} />
      </Routes>
      {/* )} */}
    </Box>
  );
}

export default App;
