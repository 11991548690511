import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { auth, db } from '../../Firebase';
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { HostingContext } from '../../contexts/HostingContext';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { SnackbarContext } from '../../contexts/SnackbarContext';

export default function AddHostDialog() {
  const { openAddingDialog, setOpenAddingDialog, getHostings } =
    React.useContext(HostingContext);
  const { show } = React.useContext(SnackbarContext);
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string | null>(null);
  const [notes, setNotes] = React.useState<string | null>(null);
  const [city, setCity] = React.useState<string | null>(null);
  const [space, setSpace] = React.useState<string | null>(null);

  const add = async () => {
    if (
      name === null ||
      space === null ||
      city === null ||
      phoneNumber?.length !== 10
    ) {
      alert('מידע לא תקין, מלאו את כל הפרטים');
      return;
    }
    setLoading(true);
    const data = {
      name,
      phoneNumber,
      space,
      city,
      notes,
      availability: true,
      userId: user.uid,
      datetime: serverTimestamp(),
    };
    await addDoc(collection(db, 'hosts'), data);
    setLoading(false);
    setOpenAddingDialog(false);
    getHostings();
    show('נוספת בהצלחה', 'success');
  };

  const handleClose = () => {
    setOpenAddingDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openAddingDialog}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        id='hostdialog'
      >
        <DialogTitle id='alert-dialog-title'>הרשם כמארח</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            איזה מדהימים אתם. תמלאו את הפרטים הבאים ונוסיף אתכם לרשימה
          </DialogContentText>
          <Stack direction={'column'} sx={{ mt: 2 }} spacing={2}>
            <TextField
              fullWidth
              label={'שם'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              label={'עיר'}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              fullWidth
              label={'מספר מקומות אירוח'}
              value={space}
              type='number'
              onChange={(e) => setSpace(e.target.value)}
            />
            <TextField
              fullWidth
              label={'הערות נוספות'}
              value={notes}
              multiline
              minRows={3}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              fullWidth
              label={'טלפון לבירורים'}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ textAlign: 'end' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>+972</InputAdornment>
                ),
              }}
            />
            <LoadingButton onClick={add} variant='contained' loading={loading}>
              תוסיפו אותי
            </LoadingButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ביטול</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
