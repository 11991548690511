import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthContextProvider from './contexts/AuthContext';
import SnackbarContextProvider from './contexts/SnackbarContext';
import UserContextProvider from './contexts/UserContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';
import Hotjar from '@hotjar/browser';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import HostingContextProvider from './contexts/HostingContext';
import RidesContextProvider from './contexts/RidesContext';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Initiate Hotjar only in prod
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  const siteId = 3603373;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.NODE_ENV === 'development',
  });
}

// Initiate Mixpanel only in prod
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL ?? '', {
    debug: process.env.NODE_ENV === 'development',
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CacheProvider value={cacheRtl}>
      <ThemeProvider>
        <SnackbarContextProvider>
          <AuthContextProvider>
            <HostingContextProvider>
              <RidesContextProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </LocalizationProvider>
              </RidesContextProvider>
            </HostingContextProvider>
          </AuthContextProvider>
        </SnackbarContextProvider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
