import React, { createContext, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore';

interface RidesContextProps {
  rides?: any[];
  openAddingDialog: boolean;
  setOpenAddingDialog: (state: boolean) => void;
  getRides?: any;
}

export const RidesContext = createContext<RidesContextProps>({
  rides: [],
  setOpenAddingDialog: () => {},
  openAddingDialog: false,
});

function RidesContextProvider(props: any) {
  const [openAddingDialog, setOpenAddingDialog] = React.useState(false);
  const [rides, setRides] = React.useState<any[]>([]);

  useEffect(() => {
    getRides();
  }, []);

  const getRides = async () => {
    const querySnapshot = await getDocs(collection(db, 'rides'));
    setRides(querySnapshot.docs);
  };

  return (
    <RidesContext.Provider
      value={{
        rides,
        openAddingDialog,
        setOpenAddingDialog,
        getRides,
      }}
    >
      {props.children}
    </RidesContext.Provider>
  );
}

export default RidesContextProvider;
