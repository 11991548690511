import {
  Diversity1Rounded,
  FavoriteRounded,
  HotelRounded,
  MessageRounded,
  PeopleOutline,
  TimeToLeaveRounded,
} from '@mui/icons-material';
import { SxProps, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import DashboardAppBar from './DashboardAppBar';
import DashboardDrawer, { drawerWidth } from './DashboardDrawer';

const CONTENT: SxProps = {
  bgcolor: 'background.default',
  p: 3,
  width: `calc(100% - ${drawerWidth}px)`,
  ml: `${drawerWidth}px`,
};

const PAGE_TOOLBAR: SxProps = {
  height: 64 + 16,
};

export interface MenuItem {
  id: string;
  name: string;
  path: string;
  icon: ReactNode;
  actions?: ReactNode;
  hide?: boolean;
  active?: boolean;
}

export default function DashboardLayout() {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        id: 'home',
        name: 'ראשי',
        path: '/home',
        icon: <Diversity1Rounded />,
      },
      {
        id: 'hosting',
        name: 'אירוח',
        path: '/hosting',
        icon: <HotelRounded />,
      },
      {
        id: 'rides',
        name: 'הסעות',
        path: '/rides',
        icon: <TimeToLeaveRounded />,
      },
      {
        id: 'mental-support',
        name: 'תמיכה נפשית',
        path: '/mental-support',
        icon: <FavoriteRounded />,
      },
      // {
      //   id: 'whatsapp-groups',
      //   name: 'קבוצות ווצאפ',
      //   path: '/whatsapp-groups',
      //   icon: <MessageRounded />,
      // },
    ];

    return items.map((item) => ({
      ...item,
      active: location.pathname.includes(item.path),
    }));
  }, [location.pathname]);

  const bottomMenuItems = useMemo(() => {
    const items: MenuItem[] = [];

    return items.map((item) => ({
      ...item,
      active: location.pathname.includes(item.path),
    }));
  }, [location.pathname]);

  const nonHiddenMenuItems: MenuItem[] = useMemo(
    () => menuItems.filter((a) => !a.hide),
    [menuItems]
  );

  const nonHiddenBottomMenuItems: MenuItem[] = useMemo(
    () => bottomMenuItems.filter((a) => !a.hide),
    [bottomMenuItems]
  );

  const activeMenuItem = useMemo(
    () =>
      menuItems.find((item) => item.active) ||
      bottomMenuItems.find((item) => item.active),
    [menuItems]
  );

  return (
    <>
      <DashboardAppBar
        activeMenuItem={activeMenuItem}
        isMobile={isMobile}
        setOpen={setOpen}
      />
      <DashboardDrawer
        open={open}
        setOpen={setOpen}
        menuItems={nonHiddenMenuItems}
        bottomItems={nonHiddenBottomMenuItems}
        isMobile={isMobile}
      />
      <Box
        component='main'
        sx={{
          ...CONTENT,
          width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
          ml: isMobile ? 0 : `${drawerWidth}px`,
        }}
      >
        <Toolbar sx={PAGE_TOOLBAR} />
        <Outlet />
      </Box>
    </>
  );
}
