import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { track } from '../utils/analytics';
import CustomCard from '../components/CustomCard/CustomCard';
import CustomCardHeader from '../components/CustomCard/CustomCardHeader';
import HostingTable from '../components/Hosting/HostingTable';
import TrempsTable from '../components/Hosting/TrempsTable';
import { RidesContext } from '../contexts/RidesContext';
import { HostingContext } from '../contexts/HostingContext';

const MentalHelp = () => {
  const navigate = useNavigate();
  const { rides } = useContext(RidesContext);
  const { hostings } = useContext(HostingContext);

  useEffect(() => {}, []);

  return (
    <Container maxWidth={'md'} disableGutters>
      <Typography variant='h1' sx={{ textAlign: 'start' }}>
        תמיכה נפשית
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={12}>
          <CustomCard>
            <CustomCardHeader title='מרכזי חוסן' />
            <Box
              sx={{
                fontSize: 16,
                textAlign: 'start',
                paddingInlineStart: 1,
                color: 'black',
              }}
            >
              לתושבי כלל הארץ:
              <br />
              כללית 03-7472010
              <br />
              מכבי *3555
              <br />
              לאומית 1700507507
              <br />
              *507 מאוחדת *3833
              <br />
              ער"ן -1201
              <br />
              נט"ל - 1-800-363-363
              <br />
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MentalHelp;
