import { Components, Theme } from '@mui/material';

export default function themedMuiPaper(
  theme: Theme,
): Pick<Components<Theme>, 'MuiPaper'> {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
