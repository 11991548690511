import { Button, IconButton, SxProps, TextField } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DashboardAvatar from './DashboardAvatar';
import { drawerWidth } from './DashboardDrawer';
import { MenuItem } from './DashboardLayout';
import LoginDialog from '../dialogs/LoginDialog';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { MenuRounded } from '@mui/icons-material';
import logo from '../../assets/images/logo.png';

const APP_BAR: SxProps = {
  width: `calc(100% - ${drawerWidth}px)`,
  ml: `${drawerWidth - 20}px`,
  bgcolor: 'background.default',
  height: 72 + 16,
};

const PAGE_TITLE: SxProps = {
  color: 'black',
  flexGrow: 1,
  textAlign: 'start',
};

const HEADER_TOOLBAR: SxProps = {
  height: 88,
  paddingInlineEnd: '12px !important',
  width: '100%',
};

export interface DashboardAppBar {
  activeMenuItem?: MenuItem;
  isMobile?: boolean;
  setOpen: any;
}

export default function DashboardAppBar({
  activeMenuItem,
  isMobile,
  setOpen,
}: DashboardAppBar) {
  const { user, setOpenLoginDialog } = useContext(AuthContext);
  return (
    <AppBar
      position='fixed'
      elevation={0}
      sx={{
        ...APP_BAR,
        width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
        ml: isMobile ? 0 : `${drawerWidth - 20}px`,
      }}
    >
      <Toolbar sx={HEADER_TOOLBAR}>
        {isMobile && <img src={logo} style={{ height: 82 }} />}
        {isMobile && <div style={{ flexGrow: 1 }} />}
        {!isMobile ? (
          <Typography variant='h6' noWrap component='div' sx={PAGE_TITLE}>
            {activeMenuItem?.name}
          </Typography>
        ) : (
          <IconButton onClick={() => setOpen(true)}>
            <MenuRounded />
          </IconButton>
        )}
        {/* {activeMenuItem?.actions ?? <></>} */}
        {isMobile === false && (
          <>
            {user ? (
              <DashboardAvatar />
            ) : (
              <Button onClick={() => setOpenLoginDialog(true)}>התחברות</Button>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
