// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyASubjV5PwsqKUsg3GQh8Gd2RKU1y2WWS8',
  authDomain: 'wintogether-50137.firebaseapp.com',
  projectId: 'wintogether-50137',
  storageBucket: 'wintogether-50137.appspot.com',
  messagingSenderId: '1071065404212',
  appId: '1:1071065404212:web:f0a6b79fdc33d0479f4b61',
  measurementId: 'G-5D1ZWKRY7H',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const storage = getStorage();

// connectFunctionsEmulator(functions, 'localhost', 5001);

export { analytics, app, auth, db, functions, storage };
