import React, { createContext, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore';

interface HostingContextProps {
  hostings?: any[];
  openAddingDialog: boolean;
  setOpenAddingDialog: (state: boolean) => void;
  getHostings?: any;
}

export const HostingContext = createContext<HostingContextProps>({
  hostings: [],
  setOpenAddingDialog: () => {},
  openAddingDialog: false,
});

function HostingContextProvider(props: any) {
  const [openAddingDialog, setOpenAddingDialog] = React.useState(false);
  const [hostings, setHostings] = React.useState<any[]>([]);

  useEffect(() => {
    getHostings();
  }, []);

  const getHostings = async () => {
    const querySnapshot = await getDocs(collection(db, 'hosts'));
    setHostings([...querySnapshot.docs]);
  };

  return (
    <HostingContext.Provider
      value={{
        hostings,
        openAddingDialog,
        setOpenAddingDialog,
        getHostings,
      }}
    >
      {props.children}
    </HostingContext.Provider>
  );
}

export default HostingContextProvider;
