// makes sure a function only invoes once,
export function concurrentSafeExecution<T extends Function>(asyncFn: T): T {
  let promise: Promise<T> | null = null;

  const execute = async (...args: any[]): Promise<T> => {
    if (!promise) {
      const _promise = asyncFn(...args);
      promise = _promise;
      if (_promise?.finally) {
        _promise.finally(() => (promise = null));
      } else {
        promise = null;
      }

      return _promise;
    }
    return promise;
  };

  return execute as any;
}

export function distinct<T>(items: T[], getValue?: (item: T) => string): T[] {
  const distinctItems: T[] = [];
  const uniqueValues: Set<string> = new Set();

  const _getValue = getValue || ((i) => `${i}`);
  for (const item of items) {
    const value = _getValue(item);

    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      distinctItems.push(item);
    }
  }

  return distinctItems;
}

export function formatDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (minutes > 0) {
    result += `${minutes}m `;
  }

  if (remainingSeconds > 0 || result === '') {
    result += `${remainingSeconds}s`;
  }

  return result.trim();
}

export async function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(null), ms);
  });
}
