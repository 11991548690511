import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RidesContext } from '../../contexts/RidesContext';
import moment from 'moment';
import { Button, IconButton, Tooltip } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { EmailRounded, Message } from '@mui/icons-material';

export default function TrempsTable({ fromOverview = false }: any) {
  const { rides } = React.useContext(RidesContext);
  const { isAuthenticated, setOpenLoginDialog } = React.useContext(AuthContext);
  const getData = () => {
    let data = rides?.map((item) => item.data()) || [];
    if (fromOverview) {
      return data.slice(0, 10);
    }
    return data || [];
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>שם</TableCell>
            <TableCell align='left'>עיר יציאה</TableCell>
            <TableCell align='left'>עיר יעד</TableCell>
            <TableCell align='left'>מקומות</TableCell>
            <TableCell align='left'>תאריך וזמן יציאה</TableCell>
            <TableCell align='left'>נוסף ב</TableCell>
            <TableCell align='left'>הערות נוספות</TableCell>
            <TableCell align='left'>טלפון</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getData().map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.from}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.to}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.space}
              </TableCell>
              <TableCell component='th' scope='row'>
                {moment(row.leavingTime).format('lll')}
              </TableCell>
              <TableCell component='th' scope='row'>
                {moment(row.timestamp).format('lll')}
              </TableCell>
              <TableCell component='th' scope='row'>
                <Tooltip title={row?.notes || 'N/A'} placement='top'>
                  <IconButton>
                    <EmailRounded />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell component='th' scope='row'>
                {isAuthenticated ? (
                  <Button
                    size='small'
                    href={`tel:${row.phoneNumber}`}
                    variant='contained'
                  >
                    להתקשר
                  </Button>
                ) : (
                  <Button
                    size='small'
                    onClick={() => setOpenLoginDialog(true)}
                    variant='contained'
                  >
                    להתקשר
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
