import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HostingContext } from '../../contexts/HostingContext';
import moment from 'moment';
import { Button, IconButton, Snackbar, Tooltip } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import { EmailRounded, Message } from '@mui/icons-material';
import { db } from '../../Firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { SnackbarContext } from '../../contexts/SnackbarContext';

export default function HostingTable({ fromOverview = false }: any) {
  const { hostings, getHostings } = React.useContext(HostingContext);
  const { show } = React.useContext(SnackbarContext);
  const { isAuthenticated, user, setOpenLoginDialog } =
    React.useContext(AuthContext);

  const getData = () => {
    let data =
      hostings?.map((item) => ({ docId: item.id, ...item.data() })) || [];
    if (fromOverview) {
      return data.slice(0, 10);
    }
    return data || [];
  };

  const changeToNotAvailable = async (row: any) => {
    const docRef = doc(db, 'hosts', row.docId);
    const newData = {
      ...row,
      availability: false,
    };
    try {
      await updateDoc(docRef, newData);
      await getHostings();
      show('הפך ללא זמין', 'success');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>שם</TableCell>
            <TableCell align='left'>עיר</TableCell>
            <TableCell align='left'>מספר מקומות</TableCell>
            <TableCell align='left'>מצב זמינות</TableCell>
            <TableCell align='left'>נוסף ב</TableCell>
            <TableCell align='left'>הערות נוספות</TableCell>
            <TableCell align='left'>טלפון</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getData().map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.city}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.space}
              </TableCell>
              {isAuthenticated &&
              user.uid === row.userId &&
              user.availability === true ? (
                <TableCell component='th' scope='row'>
                  <Button onClick={() => changeToNotAvailable(row)}>
                    הפוך ללא זמין
                  </Button>
                </TableCell>
              ) : (
                <TableCell component='th' scope='row'>
                  {row.availability ? 'זמין' : 'כבר לא זמין'}
                </TableCell>
              )}
              <TableCell component='th' scope='row'>
                {moment(row.timestamp).format('lll')}
              </TableCell>
              <TableCell component='th' scope='row'>
                <Tooltip title={row?.notes || 'N/A'} placement='top'>
                  <IconButton>
                    <EmailRounded />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell component='th' scope='row'>
                {isAuthenticated ? (
                  <Button
                    size='small'
                    href={`tel:${row.phoneNumber}`}
                    variant='contained'
                  >
                    להתקשר
                  </Button>
                ) : (
                  <Button
                    size='small'
                    onClick={() => setOpenLoginDialog(true)}
                    variant='contained'
                  >
                    להתקשר
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
