import {
  Checkbox,
  ListItemIcon,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React, { MouseEventHandler, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { AuthContext } from '../../contexts/AuthContext';
import { track } from '../../utils/analytics';
import { MenuItem } from './DashboardLayout';
import DashboardAvatar from './DashboardAvatar';
import LoginDialog from '../dialogs/LoginDialog';

export const drawerWidth = 260;

const DRAWER: SxProps = {
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    border: 'none',
    bgcolor: 'white',
    boxShadow: '0px 4px 15px rgba(19, 31, 44, 0.05)',
  },
};

const DRAWER_TOOLBAR: SxProps = {
  height: 104,
  m: 2,
  mt: 1.5,
  mb: 1.5,
};

const TAB_BUTTON: SxProps = {
  height: 54,
  borderRadius: 1.5,
  p: 1,
  paddingInline: 2,
  mt: 1.5,
  mb: 1.5,
};

const TAB_BUTTON_ACTIVE: SxProps = {
  ...TAB_BUTTON,
  bgcolor: 'primary.main',
  ':hover': {
    bgcolor: 'primary.main',
  },
};

const TAB_BUTTON_ICON_WRAPPER = (color: string): SxProps => ({
  minWidth: 36,
  color,
});

const TAB_CONTENT: SxProps = {
  color: 'black',
  fontWeight: 600,
};

const TAB_CONTENT_ACTIVE: SxProps = {
  color: 'white',
  fontWeight: 600,
};

const LIST: SxProps = { pl: 2, pr: 2 };

interface Props {
  menuItems: MenuItem[];
  bottomItems?: MenuItem[];
}

export default function DashboardDrawer({
  menuItems,
  bottomItems,
  open,
  setOpen,
}: any) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { user } = useContext(AuthContext);
  return (
    <Drawer
      sx={DRAWER}
      onClose={() => setOpen(false)}
      variant={isMobile ? 'temporary' : 'permanent'}
      open={open}
      anchor='left'
    >
      <Toolbar sx={DRAWER_TOOLBAR} disableGutters>
        <img src={logo} style={{ height: 104 }} />
      </Toolbar>
      {/* <DashboardAvatar /> */}
      <Stack
        direction={'column'}
        alignItems={'space-between'}
        justifyContent={'space-between'}
        flex={1}
        sx={{ height: '100%' }}
      >
        <List sx={LIST}>
          {menuItems.map((item: any) => (
            <_DrawerItem key={item.name} item={item} />
          ))}
          {isMobile && <>{user ? <DashboardAvatar /> : <LoginDialog />}</>}
          <Stack style={{}} justifyContent={'start'}>
            <div
              style={{
                fontSize: 14,
                marginTop: 16,
                paddingInlineStart: isMobile ? 8 : 0,
              }}
            >
              לשיתוף פעולה:
            </div>
            <div
              style={{
                fontSize: 14,
                marginTop: 8,
                paddingInlineStart: isMobile ? 8 : 0,
              }}
            >
              <a href='mailto:avichay.official@gmail.com'>שלחו מייל</a>
            </div>
          </Stack>
        </List>
      </Stack>
    </Drawer>
  );
}

function _DrawerItem({
  item,
  onClick,
}: {
  item: MenuItem;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  const navigate = useNavigate();

  return (
    <ListItemButton
      key={item.name}
      id={`drawer-item-${item.id}`}
      sx={item.active ? TAB_BUTTON_ACTIVE : TAB_BUTTON}
      onClick={
        onClick ??
        (() => {
          track('drawer_tab_button_click', { tab: item.name });
          navigate(item.path);
        })
      }
    >
      <ListItemIcon
        sx={TAB_BUTTON_ICON_WRAPPER(item.active ? 'white' : '#969BA9')}
      >
        {React.cloneElement(item.icon as any, {
          color: item.active ? 'white' : '#969BA9',
        })}
      </ListItemIcon>
      <ListItemText
        primary={item.name}
        sx={item.active ? TAB_CONTENT_ACTIVE : TAB_CONTENT}
      />
    </ListItemButton>
  );
}
