import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { auth, db } from '../../Firebase';
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { HostingContext } from '../../contexts/HostingContext';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { RidesContext } from '../../contexts/RidesContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function AddRideDialog() {
  const { openAddingDialog, setOpenAddingDialog, getRides } =
    React.useContext(RidesContext);
  const { show } = React.useContext(SnackbarContext);
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string | null>(null);
  const [from, setFrom] = React.useState<string | null>(null);
  const [to, setTo] = React.useState<string | null>(null);
  const [space, setSpace] = React.useState<string | null>(null);
  const [notes, setNotes] = React.useState<string | null>(null);
  const [leavingDateTime, setLeavingDateTime] = React.useState<string | null>(
    null
  );

  const add = async () => {
    if (
      name === null ||
      space === null ||
      from === null ||
      to === null ||
      leavingDateTime === null ||
      phoneNumber?.length !== 10
    ) {
      alert('מידע לא תקין, מלאו את כל הפרטים');
      return;
    }
    setLoading(true);
    const data = {
      name,
      phoneNumber,
      from,
      to,
      space,
      notes,
      availability: true,
      leavingTime: dayjs(leavingDateTime).valueOf(),
      datetime: serverTimestamp(),
      userId: user.uid,
    };
    await addDoc(collection(db, 'rides'), data);
    setLoading(false);
    setOpenAddingDialog(false);
    getRides();
    show('נוספת בהצלחה', 'success');
  };

  const handleClose = () => {
    setOpenAddingDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openAddingDialog}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        id='ridedialog'
      >
        <DialogTitle id='alert-dialog-title'>הוספת נסיעה</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            אלופים. מלא/י את הפרטים ונוספים אותך לרשימה
          </DialogContentText>
          <Stack direction={'column'} sx={{ mt: 2 }} spacing={2}>
            <TextField
              fullWidth
              label={'שם'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              label={'עיר מוצא'}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
            <TextField
              fullWidth
              label={'עיר יעד'}
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
            <TextField
              fullWidth
              label={'מספר מקומות'}
              value={space}
              type='number'
              onChange={(e) => setSpace(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={'תאריך ושעת יציאה'}
                value={leavingDateTime}
                onChange={(value) => setLeavingDateTime(value)}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              label={'הערות נוספות'}
              value={notes}
              multiline
              minRows={3}
              onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
              fullWidth
              label={'טלפון לבירורים'}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ textAlign: 'end' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>+972</InputAdornment>
                ),
              }}
            />
            <LoadingButton onClick={add} variant='contained' loading={loading}>
              תוסיפו אותי
            </LoadingButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ביטול</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
