import { Button, Container, Fab, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { track } from '../utils/analytics';
import CustomCard from '../components/CustomCard/CustomCard';
import CustomCardHeader from '../components/CustomCard/CustomCardHeader';
import HostingTable from '../components/Hosting/HostingTable';
import TrempsTable from '../components/Hosting/TrempsTable';
import { RidesContext } from '../contexts/RidesContext';
import { HostingContext } from '../contexts/HostingContext';
import { Add } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';

const Overview = () => {
  const navigate = useNavigate();
  const { rides, setOpenAddingDialog: setOpenAddingRideDialog } =
    useContext(RidesContext);
  const { hostings, setOpenAddingDialog } = useContext(HostingContext);
  const { isAuthenticated, setOpenLoginDialog } = useContext(AuthContext);

  useEffect(() => {}, []);

  const addRide = () => {
    if (isAuthenticated === false) {
      setOpenLoginDialog(true);
      return;
    }
    setOpenAddingRideDialog(true);
  };

  const addHost = () => {
    if (isAuthenticated === false) {
      setOpenLoginDialog(true);
      return;
    }
    setOpenAddingDialog(true);
  };

  return (
    <Container maxWidth={'md'} disableGutters>
      <Typography
        sx={{
          textAlign: 'start',
          fontWeight: 'bold',
          fontSize: { xs: 24, sm: 32, md: 42 },
          mt: 2,
        }}
      >
        פלטפורמת ארצית להתנדבויות ועזרה הדדית בשעת חירום - ביחד ננצח!
      </Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={4}>
          <CustomCard sx={{ minHeight: 124 }}>
            <CustomCardHeader title='מקומות אירוח' />
            <Typography
              sx={{
                fontSize: 32,
                textAlign: 'start',
                paddingInlineStart: 1,
                fontWeight: 700,
                color: '#8C52FF',
              }}
            >
              {hostings?.length || 0}
            </Typography>
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard sx={{ minHeight: 124 }}>
            <CustomCardHeader title='טרמפים' />
            <Typography
              sx={{
                fontSize: 32,
                textAlign: 'start',
                paddingInlineStart: 1,
                fontWeight: 700,
                color: '#FF66C3',
              }}
            >
              {rides?.length || 0}
            </Typography>
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard sx={{ minHeight: 124 }}>
            <CustomCardHeader title='קבוצת ווצאפ - דולות תומכות בנשים בהריון' />
            <a
              style={{ marginTop: 8, textAlign: 'start', width: '100%' }}
              href='https://tinyurl.com/5fm56v2r'
            >
              קישור לקבוצת הווצאפ
            </a>
          </CustomCard>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <CustomCard>
            <CustomCardHeader title='טרמפים מהצפון לדרום' />
            <Typography
              sx={{
                fontSize: 32,
                textAlign: 'start',
                paddingInlineStart: 1,
                fontWeight: 700,
                color: '#F58710',
              }}
            >
              81
            </Typography>
          </CustomCard>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <CustomCard>
            <CustomCardHeader
              title='מקומות אירוח'
              action={
                <Fab
                  variant='extended'
                  size='small'
                  color='primary'
                  onClick={() => addHost()}
                >
                  <Add sx={{ mr: 1 }} />
                  רוצה לארח
                </Fab>
              }
            />
            <HostingTable fromOverview />
            <Stack
              direction={'row'}
              justifyContent={'end'}
              sx={{ width: '100%', mt: 1 }}
            >
              <Button
                sx={{ color: 'black', p: 0 }}
                onClick={() => navigate('/hosting')}
              >
                <u>לכל מקומות האירוח</u>
              </Button>
            </Stack>
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomCard>
            <CustomCardHeader
              title='טרמפים'
              action={
                <Fab
                  variant='extended'
                  size='small'
                  color='primary'
                  onClick={() => addRide()}
                >
                  <Add sx={{ mr: 1 }} />
                  הוספת נסיעה
                </Fab>
              }
            />
            <TrempsTable fromOverview />
            <Stack
              direction={'row'}
              justifyContent={'end'}
              sx={{ width: '100%', mt: 1 }}
            >
              <Button
                sx={{ color: 'black', p: 0 }}
                onClick={() => navigate('/rides')}
              >
                <u>לכל הטרמפים</u>
              </Button>
            </Stack>
          </CustomCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Overview;
