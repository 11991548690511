import axios, { AxiosRequestConfig } from 'axios';
import {
  AUTH_REFRESH_TOKEN_STORAGE_KEY,
  AUTH_TOKEN_STORAGE_KEY,
} from '../constants/params';
import { concurrentSafeExecution } from '../utils';

const BASE = process.env.REACT_APP_WIN_TOGETHER_API_BASE_URL;

const instance = axios.create({
  baseURL: BASE,
  headers: {
    'Content-type': 'application/json',
  },
});

export const onRefreshError: { cb?: () => void } = {};

export const getStoredAccessToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
};

export const storeAccessToken = (accessToken?: string) => {
  if (!accessToken) {
    sessionStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  } else {
    sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, accessToken);
  }
};

export const getStoredRefreshToken = () => {
  return localStorage.getItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
};

export const storeRefreshToken = (refreshToken?: string) => {
  if (!refreshToken) {
    localStorage.removeItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
  } else {
    localStorage.setItem(AUTH_REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }
};

const refreshToken = concurrentSafeExecution(async () => {
  try {
    const token = getStoredRefreshToken();
    if (!token) return;

    const resp = await axios.post<{ token: string }>(BASE + '/auth/refresh', {
      refresh_token: token,
    });
    return resp.data;
  } catch (e) {
    console.error('Error', e);
  }
});

instance.interceptors.request.use(
  async (config) => {
    const token = getStoredAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const resp = await refreshToken();
      if (!resp) {
        onRefreshError.cb?.();
        return Promise.reject(error);
      }

      const accessToken = resp.token;

      storeAccessToken(accessToken);

      return instance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export async function request(path: string, config?: AxiosRequestConfig) {
  return instance(path, {
    ...config,
  });
}

export async function get(path: string, config?: AxiosRequestConfig) {
  return request(path, { ...config, method: 'get' });
}

export async function post(
  path: string,
  params: any,
  config?: AxiosRequestConfig,
) {
  return request(path, {
    ...config,
    method: 'post',
    data: JSON.stringify(params),
  });
}

export async function put(
  path: string,
  params: any,
  config?: AxiosRequestConfig,
) {
  return request(path, {
    ...config,
    method: 'put',
    data: JSON.stringify(params),
  });
}
