import { Components, Theme } from '@mui/material';

export default function themedMuiFilledInput(
  theme: Theme,
): Pick<Components<Theme>, 'MuiFilledInput' | 'MuiInputAdornment'> {
  return {
    MuiFilledInput: {
      defaultProps: {
        hiddenLabel: true,
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#F5F8FD',
          borderRadius: 6,
          fontSize: 16,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
        filled: {
          marginTop: 0,
        },
        sizeSmall: {
          marginTop: 0,
        },
        hiddenLabel: {
          marginTop: 0,
        },
      },
    },
  };
}
