import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { auth } from '../../Firebase';
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { AuthContext } from '../../contexts/AuthContext';

export default function LoginDialog({ hideButton = false }: any) {
  const { openLoginDialog, setOpenLoginDialog } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [error, setError] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [verificationObject, setVerificationObject] =
    React.useState<ConfirmationResult | null>(null);

  const handleSendCode = async () => {
    if (phoneNumber === '' || phoneNumber.length < 10) return;
    setLoading(true);
    let verify = new RecaptchaVerifier(auth, 'recaptcha-container');
    signInWithPhoneNumber(auth, `+972${phoneNumber}`, verify)
      .then((result) => {
        setVerificationObject(result);
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  const handleVerifyCode = async () => {
    if (verificationCode === null || verificationObject === null) return;
    setError(false);
    setLoading(true);
    verificationObject
      .confirm(verificationCode)
      .then((result) => {
        setLoading(false);
        setOpenLoginDialog(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  const handleClickOpen = () => {
    setOpenLoginDialog(true);
  };

  const handleClose = () => {
    setOpenLoginDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openLoginDialog}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby='new-cluster-dialog-title'
        aria-describedby='new-cluster-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>התחברות</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            כדי להוסיף רשומות חדשות או להתקשר יש להתחבר עם מספר טלפון ישראלי.
          </DialogContentText>
          <DialogContentText id='alert-dialog-description'>
            התחברות מתבצעת ע״י אימות של מספר טלפון. הכנס/י מספר טלפון, הזינו את
            הקוד שקיבלתם בהודעת SMS ואתם מחוברים.
          </DialogContentText>
          {verificationObject ? (
            <Stack direction={'column'} sx={{ mt: 2 }} spacing={2}>
              <TextField
                fullWidth
                label={'קוד אימות'}
                value={verificationCode}
                error={error}
                helperText={error ? 'קוד שגוי' : ''}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <LoadingButton
                onClick={handleVerifyCode}
                variant='contained'
                loading={loading}
              >
                אימות
              </LoadingButton>
            </Stack>
          ) : (
            <Stack direction={'column'} sx={{ mt: 2 }} spacing={2}>
              <TextField
                fullWidth
                value={phoneNumber}
                label={'מספר טלפון'}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{ textAlign: 'end' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>+972</InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                onClick={handleSendCode}
                variant='contained'
                loading={loading}
              >
                שלחו לי קוד לטלפון
              </LoadingButton>
              <div id='recaptcha-container'></div>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ביטול</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
